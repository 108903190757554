import './css/main.css'
import './css/vendor.css'
import boardWebp from './img/board.webp'
import board2Webp from './img/board-2.webp'
import board4Webp from './img/board-4.webp'
import avocadoWebp from './img/avocado.webp'
import safeWebp from './img/safe.webp'
import sprite from './img/sprite.svg'
import { contractABI, contractAddress, stakingTokenABI, stakingTokenAddress } from './contractInfo'
import { useEffect, useState } from 'react'
import { ethers } from 'ethers'
import { checkMetamaskInstallation, switchNetwork } from './web3Utils'
import { PropagateLoader } from 'react-spinners'
import { Snackbar } from '@mui/material'
import moment from 'moment'
import QRCode from 'react-qr-code'

function App() {
  const [loading, setLoading] = useState(false)
  const [alertShow, setAlertShow] = useState(false)
  const [alertText, setAlertText] = useState('')
  const [stakingTab, setStakingTab] = useState(0)
  const [activeSpoller, setActiveSpoller] = useState(0)
  const [activeSpollerTab, setActiveSpollerTab] = useState(0)
  const [modalShow, setModalShow] = useState(false)
  const [modalUnstakeShow, setModalUnstakeShow] = useState(false)
  const [stakingDuration, setStakingDuration] = useState(0)
  const [unstakeObject, setUnstakeObject] = useState(null)
  const [account, setAccount] = useState(null)
  const [provider, setProvider] = useState(null)
  const [signer, setSigner] = useState(null)
  const [contract, setContract] = useState(null)
  const [currentChainId, setCurrentChainId] = useState(window.ethereum?.chainId ?? '0x66eee')

  const [durations, setDurations] = useState([])
  const [rates, setRates] = useState([])

  const [myStakes, setMyStakes] = useState([])
  const [referralInfo, setReferralInfo] = useState({ fstLevelNumber: 0, sndLevelNumber: 0 })

  const rpcUrls = [
    'https://arbitrum-sepolia.blockpi.network/v1/rpc/public',
    'https://sepolia-rollup.arbitrum.io/rpc',
    'https://endpoints.omniatech.io/v1/arbitrum/sepolia/public', // ?
    'https://arbitrum-sepolia.gateway.tenderly.co',
  ]

  useEffect(() => {
    const getContractInfo = async () => {
      const providerTemp = new ethers.JsonRpcProvider(rpcUrls[1])
      const contractTemp = new ethers.Contract(contractAddress, contractABI, providerTemp)
      const durations = await contractTemp.getDurations()
      const rates = await contractTemp.getRates()
      setDurations(durations)
      setRates(rates)
    }

    getContractInfo()
  }, [])

  const getAccountInfo = async () => {
    const numberOfStakes = Number(await contract.userID(account))

    const stakeListProxy = await Promise.all(
      Array.from({ length: numberOfStakes }, (_, i) => contract.userStaked(account, i + 1))
    )
    const rewardListProxy = await Promise.all(
      Array.from({ length: numberOfStakes }, (_, i) => contract.getReward(account, i + 1))
    )
    const referralInfoProxy = await contract.userReferralInfo(account)
    setReferralInfo({ fstLevelNumber: Number(referralInfoProxy[1]), sndLevelNumber: Number(referralInfoProxy[2]) })
    const stakeList = JSON.parse(
      JSON.stringify(stakeListProxy, (key, value) => (typeof value === 'bigint' ? value.toString() : value))
    ).map((item, i) => ({
      amount: item[0],
      lastClaim: item[1],
      stakedTime: item[2],
      duration: item[3],
      rate: item[4],
      position: item[5],
      earned: item[6],
      reward: rewardListProxy[i].toString(),
    }))
    setMyStakes(stakeList)
  }

  const stakeHandler = async (stakeValue) => {
    setLoading(true)
    if (contract) {
      const stakeAmount = ethers.parseEther(stakeValue.toString())
      const tokenContract = new ethers.Contract(stakingTokenAddress, stakingTokenABI, signer)
      try {
        const allowance = await tokenContract.allowance(signer.getAddress(), contractAddress)
        console.log('allowance:', allowance)
        if (allowance < stakeAmount) {
          const approvalTx = await tokenContract.approve(contractAddress, stakeAmount)
          console.log('approvalTx:', approvalTx)
          await approvalTx.wait() // Wait for the approval transaction to be mined
          console.log('Tokens approved for staking')
        } else {
          console.log('Tokens already approved')
        }
        const tx = await contract.stake(stakeAmount, stakingDuration, '0x0000000000000000000000000000000000000000')
        await tx.wait()
        setAlertText(stakeValue + ' Tokens were staked successfully')
        setAlertShow(true)
        setModalShow(false)
        getAccountInfo()
      } catch (error) {
        console.log(error)
        setAlertText(error.reason)
        setAlertShow(true)
      }
    }
    setLoading(false)
  }
  const unstakeHandler = async () => {
    setLoading(true)
    if (contract) {
      try {
        // const contractReader = new ethers.Contract(contractAddress, contractABI, provider)
        const stakeId = await contract.stakedIDs(account, unstakeObject.position)
        const tx = await contract.unStake(stakeId)
        await tx.wait()
        setAlertText(ethers.parseEther(unstakeObject.amount) + 'AVO were unstaked successfully!')
        setAlertShow(true)
        setModalUnstakeShow(false)
        getAccountInfo()
      } catch (error) {
        console.log(error)
        setAlertText(error.reason)
        setAlertShow(true)
      }
    }
    setLoading(false)
    setModalUnstakeShow(false)
  }

  const connectWallet = async () => {
    checkMetamaskInstallation(setProvider, setSigner, setContract, setAccount, setCurrentChainId)
  }

  const convertSecondsToDuration = (seconds) => {
    const duration = moment.duration(seconds, 'seconds')

    // Calculate total months and years from the duration
    const totalMonths = Math.round(duration.asMonths())
    const totalYears = Math.round(duration.asYears())

    // Return human-readable duration based on thresholds
    if (totalYears >= 1) {
      return `${totalYears} year${totalYears > 1 ? 's' : ''}`
    } else if (totalMonths >= 1) {
      return `${totalMonths} month${totalMonths > 1 ? 's' : ''}`
    } else {
      return 'less than a month'
    }
  }

  const calculateTimeLeft = (stakedTime, duration) => {
    const releaseTime = moment.unix(stakedTime + duration)
    const now = moment()
    const diff = moment.duration(releaseTime.diff(now))

    const days = Math.floor(diff.asDays())
    const hours = diff.hours()
    const minutes = diff.minutes()

    return `${days}D ${hours}H ${minutes}M`
  }

  useEffect(() => {
    // Function to handle network changes
    const handleNetworkChange = (chainId) => {
      console.log(`Network changed to: ${chainId}`)
      // Reload the page when the network changes
      setCurrentChainId(chainId)
    }

    // Check if MetaMask is installed
    if (window.ethereum) {
      // Handle network changes
      window.ethereum.on('chainChanged', handleNetworkChange)

      // Clean up the event listener on component unmount
      return () => {
        window.ethereum.removeListener('chainChanged', handleNetworkChange)
      }
    } else {
      setAlertText('MetaMask is not installed.')
      setAlertShow(true)
    }
  }, [])

  useEffect(() => {
    async function checkMetaMaskAccess() {
      if (window.ethereum) {
        try {
          const accounts = await window.ethereum.request({ method: 'eth_accounts' })

          if (accounts.length > 0) {
            await checkMetamaskInstallation(setProvider, setSigner, setContract, setAccount)
          } else {
            console.log('No accounts connected')
            return null
          }
        } catch (error) {
          console.error('Error checking MetaMask accounts:', error)
        }
      } else {
        setAlertText('MetaMask not detected')
        setAlertShow(true)
      }
    }
    checkMetaMaskAccess()
  }, [])

  useEffect(() => {
    if (contract && currentChainId === '0x66eee') {
      getAccountInfo()
    }
  }, [contract, currentChainId, account])

  useEffect(() => {
    if (modalShow || modalUnstakeShow) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.removeProperty('overflow')
    }
  }, [modalShow, modalUnstakeShow])

  return (
    <div className="App">
      <div className="wrapper">
        <header className="header">
          <div className="header__container">
            <a href="/" className="header__logo logo">
              <img className="logo__icon" src={require('./img/logo-icon.svg').default} alt="logo" />
              <div className="logo__name">
                <img className="logo__name-icon" src={require('./img/logo-name.svg').default} alt="logo" />
                <span className="logo__name-text">finance</span>
              </div>
            </a>
            <div className="header__actions">
              <button className="header__lang">
                <span className="header__lang-text">EN</span>
                <svg className="header__lang-arrow">
                  <use xlinkHref={`${sprite}#arrow`}></use>
                </svg>
              </button>
              {account ? (
                <>
                  {currentChainId !== '0x66eee' ? (
                    <button
                      onClick={() =>
                        checkMetamaskInstallation(setProvider, setSigner, setContract, setAccount, setCurrentChainId)
                      }
                    >
                      ⚠️ switch network!
                    </button>
                  ) : null}
                  <p className="header__button button">
                    {account.slice(0, 6) + '...' + account.slice(account.length - 6, account.length)}
                  </p>
                </>
              ) : (
                <button onClick={() => connectWallet()} className="header__button button">
                  Connect Wallet
                </button>
              )}
            </div>
          </div>
        </header>

        <main className="page">
          <div className="page__container">
            <div className="staking">
              <div className={`staking__content ${stakingTab === 1 ? 'staking__content--partnership' : ''}`}>
                <div className={`staking__background ${stakingTab === 1 ? 'staking__background--partnership' : ''}`}>
                  <picture>
                    <source media="(min-width: 769px)" srcSet={boardWebp} type="image/webp" />
                    <source
                      media="(max-width: 768px)"
                      srcSet={stakingTab === 0 ? board2Webp : board4Webp}
                      type="image/webp"
                    />
                    <img src={require('./img/board.png')} className="image" alt="" />
                  </picture>
                </div>
                <nav className="staking__menu menu">
                  <ul className="menu__list">
                    <li className="menu__item">
                      <div
                        onClick={() => setStakingTab(0)}
                        className={`menu__link ${stakingTab === 0 ? 'active' : ''}`}
                      >
                        Staking
                      </div>
                    </li>
                    <li className="menu__item">
                      <div
                        onClick={() => setStakingTab(1)}
                        className={`menu__link ${stakingTab === 1 ? 'active' : ''}`}
                      >
                        Partnership <span> Staking</span>
                      </div>
                    </li>
                  </ul>
                </nav>
                {stakingTab === 0 ? (
                  <div className="staking__body">
                    <div className="staking__info">
                      <p className="staking__info-text">
                        Your <span className="text-yellow">APR depends</span> on the number of users you invite, 1st and
                        2nd level referrals are taken into account:
                      </p>
                      <ul className="staking__info-list">
                        <li className="staking__info-item">
                          <span className="text-yellow">APR x 1</span>
                          Number of referrals up to 30 users
                        </li>
                        <li className="staking__info-item">
                          <span className="text-yellow">APR x 1.5</span>
                          Number of referrals from 31 to 150 users
                        </li>
                        <li className="staking__info-item">
                          <span className="text-yellow">APR x 2</span>
                          Number of referrals from 151 to 300 users
                        </li>
                        <li className="staking__info-item">
                          <span className="text-yellow">APR x 2.5</span>
                          Number of referrals from 301 to 500 users
                        </li>
                        <li className="staking__info-item">
                          <span className="text-yellow">APR x 3</span>
                          Number of referrals from 500 users
                        </li>
                      </ul>
                      {account && (
                        <div className="staking__info-referrals referrals-info">
                          <h2 className="referrals-info__title">Your referrals:</h2>
                          <div className="referrals-info__items">
                            <div className="referrals-info__item">
                              <label className="referrals-info__label">1st level</label>
                              <div className="referrals-info__amount">{referralInfo.fstLevelNumber}</div>
                            </div>
                            <div className="referrals-info__item">
                              <label className="referrals-info__label">2nd level</label>
                              <div className="referrals-info__amount">{referralInfo.sndLevelNumber}</div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="staking__actions">
                      <div className="staking__frame">
                        {account ? (
                          <div className="staking__actions-content">
                            <div className="staking__actions-qr">
                              <QRCode
                                bgColor="transparent"
                                className="staking__actions-qr-code"
                                size="auto"
                                value={account}
                                alt="qr"
                              />
                              <p className="staking__actions-qr-text">
                                Scan the QR code to receive your referral link or copy the text below
                              </p>
                            </div>
                            <div className="staking__invite">
                              <label className="staking__invite-label">Invite Link</label>
                              <div className="staking__invite-link">
                                <span className="staking__invite-address">{account}</span>
                                <button
                                  onClick={() => {
                                    navigator.clipboard.writeText(account)
                                    setAlertText('Link copied to the clipboard!')
                                    setAlertShow(true)
                                  }}
                                  className="staking__invite-btn-copy"
                                >
                                  <svg className="staking__invite-btn-icon">
                                    <use xlinkHref={`${sprite}#copy`}></use>
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="staking__actions-content">
                            <img className="staking__actions-image" src={require('./img/safe.png')} alt="safe-icon" />
                            <p className="staking__actions-text">
                              Connect your wallet and stake your AVO for more benefits
                            </p>
                            <button onClick={connectWallet} className="staking__actions-btn button">
                              Connect Wallet
                            </button>
                          </div>
                        )}
                        <div className="staking__frame-corner staking__frame-corner--1">
                          <span className="staking__frame-corner-line staking__frame-corner-line--1"></span>
                          <span className="staking__frame-corner-line staking__frame-corner-line--2"></span>
                        </div>
                        <div className="staking__frame-corner staking__frame-corner--2">
                          <span className="staking__frame-corner-line staking__frame-corner-line--1"></span>
                          <span className="staking__frame-corner-line staking__frame-corner-line--2"></span>
                        </div>
                        <div className="staking__frame-corner staking__frame-corner--3">
                          <span className="staking__frame-corner-line staking__frame-corner-line--1"></span>
                          <span className="staking__frame-corner-line staking__frame-corner-line--2"></span>
                        </div>
                        <div className="staking__frame-corner staking__frame-corner--4">
                          <span className="staking__frame-corner-line staking__frame-corner-line--1"></span>
                          <span className="staking__frame-corner-line staking__frame-corner-line--2"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="staking__body staking__body--partnership">
                    <div className="staking__info staking-partnership__info">
                      <h2 className="staking-partnership__info-title">
                        <span className="staking-partnership__info-title-avo">
                          A<span>VO</span>
                        </span>{' '}
                        finance
                      </h2>
                      <div className="staking-partnership__content">
                        <p className="staking-partnership__text">
                          <span className="text-yellow">Stake your AVO </span>to earn rewards in partner project tokens.
                        </p>
                        <div className="staking-partnership__price">
                          <div className="staking-partnership__price-item">
                            <span className="staking-partnership__price-label">TVL:</span>
                            <div className="staking-partnership__price-amount">
                              <span className="staking-partnership__price-value">$123 768.35</span>
                              <span className="staking-partnership__price-change change-up">+9.23%</span>
                            </div>
                          </div>
                          {/* <div className="staking-partnership__price-item">
                            <span className="staking-partnership__price-label"> AVO price:</span>
                            <div className="staking-partnership__price-amount">
                              <span className="staking-partnership__price-value">$0.00253</span>
                              <span className="staking-partnership__price-change change-up">+7.85%</span>
                            </div>
                          </div> */}
                        </div>
                        <a
                          target="_blank"
                          href="https://pancakeswap.finance/swap?outputCurrency=0x14EE333538B4621a600F011E508D783eA200d60e&inputCurrency=0x55d398326f99059fF775485246999027B3197955"
                          className="button staking-partnership__button"
                          rel="noreferrer"
                        >
                          buy avo
                        </a>
                      </div>
                    </div>
                    <div className="staking__actions staking__actions--partnership">
                      <div className="staking__frame">
                        <div className="staking__actions-content">
                          <div className="staking__actions-image-avo">
                            <picture>
                              <source srcSet={avocadoWebp} type="image/webp" />
                              <img src={require('./img/avocado.png')} className="image" alt="" />
                            </picture>
                          </div>
                        </div>
                        <div className="staking__frame-corner staking__frame-corner--1">
                          <span className="staking__frame-corner-line staking__frame-corner-line--1"></span>
                          <span className="staking__frame-corner-line staking__frame-corner-line--2"></span>
                        </div>
                        <div className="staking__frame-corner staking__frame-corner--2">
                          <span className="staking__frame-corner-line staking__frame-corner-line--1"></span>
                          <span className="staking__frame-corner-line staking__frame-corner-line--2"></span>
                        </div>
                        <div className="staking__frame-corner staking__frame-corner--3">
                          <span className="staking__frame-corner-line staking__frame-corner-line--1"></span>
                          <span className="staking__frame-corner-line staking__frame-corner-line--2"></span>
                        </div>
                        <div className="staking__frame-corner staking__frame-corner--4">
                          <span className="staking__frame-corner-line staking__frame-corner-line--1"></span>
                          <span className="staking__frame-corner-line staking__frame-corner-line--2"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {stakingTab === 0 ? (
                <div className="staking__pools">
                  <div className="pools">
                    <h2 className="pools__title">
                      <span className="pools__title-avo">
                        a<span>vo</span>
                      </span>{' '}
                      Staking pools
                    </h2>
                    <div className="pools__list">
                      {durations.map((duration, i) => (
                        <button
                          key={i}
                          className="pools__item item-pools card"
                          onClick={() => {
                            setModalShow(true)
                            setStakingDuration(i)
                          }}
                        >
                          <div className="item-pools__label">APR</div>
                          <div className="item-pools__profit">
                            from
                            <span className="item-pools__profit-from text-yellow"> {Number(rates[i]) / 100}%</span>
                            {/* to <span className="item-pools__profit-to text-yellow"> 17.1% </span> */}
                          </div>
                          <div className="item-pools__info">
                            <div className="item-pools__info-row">
                              <div className="item-pools__info-column">
                                <img
                                  className="item-pools__info-icon"
                                  src={require('./img/svg/calendar.svg').default}
                                  alt="calendar"
                                />
                                <div className="item-pools__info-text">Age:</div>
                              </div>
                              <div className="item-pools__info-column">
                                <div className="item-pools__info-value">
                                  {convertSecondsToDuration(Number(duration))}
                                </div>
                              </div>
                            </div>
                            <div className="item-pools__info-row">
                              <div className="item-pools__info-column">
                                <img
                                  className="item-pools__info-icon"
                                  src={require('./img/svg/thumbs.svg').default}
                                  alt="thumbs"
                                />
                                <div className="item-pools__info-text">TVL:</div>
                              </div>
                              <div className="item-pools__info-column">
                                <div className="item-pools__info-value item-pools__info-value--tvl">6.7M AVO</div>
                              </div>
                            </div>
                            <div className="item-pools__info-row">
                              <div className="item-pools__info-column">
                                <div className="item-pools__info-value">~$15 410</div>
                              </div>
                            </div>
                            <div className="item-pools__info-row">
                              <div className="item-pools__info-column">
                                <div className="item-pools__info-text">Min stake:</div>
                              </div>
                              <div className="item-pools__info-column">
                                <div className="item-pools__info-value item-pools__info-value--tvl">0 AVO</div>
                              </div>
                            </div>
                          </div>
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="staking__pools-partnership">
                  <div className="pools-partnership">
                    <h2 className="pools-partnership__title">Staking pools</h2>

                    <ul className="pools-partnership__list">
                      <li className="pools-partnership__item item-pools-partnership">
                        <div className="item-pools-partnership__label">Partnership</div>
                        <div className="item-pools-partnership__content">
                          <button
                            type="button"
                            className={`item-pools-partnership__title ${activeSpoller === 1 ? '_active' : ''}`}
                            onClick={() => setActiveSpoller(activeSpoller === 1 ? 0 : 1)}
                          >
                            <div className="item-pools-partnership__image">
                              <img
                                src={require('./img/svg/image.svg').default}
                                alt=""
                                className="item-pools-partnership__icon"
                              />
                            </div>
                            <div className="item-pools-partnership__header">
                              <div className="item-pools-partnership__column">
                                <div className="item-pools-partnership__name">NMX</div>
                                <div className="item-pools-partnership__tvl text-yellow">TVL $ 11.8K</div>
                              </div>
                              <div className="item-pools-partnership__column">
                                <div className="item-pools-partnership__apr">
                                  APR: <span className="change-up">163.76%</span>
                                </div>
                                <div className="item-pools-partnership__age">AGE: 93 days</div>
                              </div>
                            </div>
                            <div className="item-pools-partnership__arrow">
                              <svg className="item-pools-partnership__arrow-icon">
                                <use xlinkHref={`${sprite}#arrow`}></use>
                              </svg>
                            </div>
                          </button>
                          <div className={`item-pools-partnership__body ${activeSpoller === 1 ? 'active' : ''}`}>
                            <div className="item-pools-partnership__inner">
                              <div className="item-pools-partnership__descr">
                                <div className="item-pools-partnership__amount-container">
                                  <div className="item-pools-partnership__amount">
                                    <span className="text-yellow">Your stake:</span> 625 789.65257 AVO
                                  </div>
                                  <div className="item-pools-partnership__amount">
                                    <span className="text-yellow">Your earn: </span>
                                    12756.15265 NMX
                                  </div>
                                </div>
                                <div className="item-pools-partnership__timer timer">
                                  <h3 className="timer__title">Time left:</h3>
                                  <div className="timer__items">
                                    <div className="timer__block">
                                      <div className="timer__time">56</div>
                                      <div className="timer__label">D</div>
                                    </div>
                                    <div className="timer__block">
                                      <div className="timer__time">17</div>
                                      <div className="timer__label">H</div>
                                    </div>
                                    <div className="timer__block">
                                      <div className="timer__time">29</div>
                                      <div className="timer__label">M</div>
                                    </div>
                                    <div className="timer__block">
                                      <div className="timer__time">43</div>
                                      <div className="timer__label">S</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="item-pools-partnership__transactions">
                                <div className="transactions">
                                  <div className="transactions__tabs tabs">
                                    <nav className="transactions__navigation tabs__navigation">
                                      <button
                                        type="button"
                                        className={`transactions__title tabs__title ${
                                          activeSpollerTab === 0 ? '_tab-active' : ''
                                        }`}
                                        onClick={() => setActiveSpollerTab(0)}
                                      >
                                        Stake
                                      </button>
                                      <button
                                        type="button"
                                        className={`transactions__title tabs__title ${
                                          activeSpollerTab === 1 ? '_tab-active' : ''
                                        }`}
                                        onClick={() => setActiveSpollerTab(1)}
                                      >
                                        withdraw
                                      </button>
                                    </nav>
                                    <div className="transactions__content tabs__content">
                                      <div
                                        className="transactions__body tabs__body"
                                        style={{ display: activeSpollerTab === 0 ? 'block' : 'none' }}
                                      >
                                        <form className="transactions__form">
                                          <label className="transactions__label">AVO Amount</label>
                                          <div className="transactions__control">
                                            <input type="number" className="transactions__input" placeholder="500000" />
                                            {/* <button className="transactions__btn-max">MAX</button> */}
                                          </div>
                                          <button className="transactions__button button">Approve</button>
                                        </form>
                                      </div>
                                      <div
                                        className="transactions__body tabs__body"
                                        style={{ display: activeSpollerTab === 1 ? 'block' : 'none' }}
                                      >
                                        <form className="transactions__form">
                                          <label className="transactions__label">AVO Amount</label>
                                          <div className="transactions__control">
                                            <input type="number" className="transactions__input" placeholder="500000" />
                                            {/* <button className="transactions__btn-max">MAX</button> */}
                                          </div>
                                          <button className="transactions__button button">Withdraw</button>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="pools-partnership__item item-pools-partnership">
                        <div className="item-pools-partnership__label">Partnership</div>
                        <div className="item-pools-partnership__content">
                          <button
                            type="button"
                            data-spoller
                            data-spoller-close
                            className={`item-pools-partnership__title ${activeSpoller === 2 ? '_active' : ''}`}
                            onClick={() => setActiveSpoller(activeSpoller === 2 ? 0 : 2)}
                          >
                            <div className="item-pools-partnership__image">
                              <img
                                src={require('./img/svg/image.svg').default}
                                alt=""
                                className="item-pools-partnership__icon"
                              />
                            </div>
                            <div className="item-pools-partnership__header">
                              <div className="item-pools-partnership__column">
                                <div className="item-pools-partnership__name">NMX</div>
                                <div className="item-pools-partnership__tvl text-yellow">TVL $ 11.8K</div>
                              </div>
                              <div className="item-pools-partnership__column">
                                <div className="item-pools-partnership__apr">
                                  APR: <span className="change-up">163.76%</span>
                                </div>
                                <div className="item-pools-partnership__age">AGE: 93 days</div>
                              </div>
                            </div>
                            <div className="item-pools-partnership__arrow">
                              <svg className="item-pools-partnership__arrow-icon">
                                <use xlinkHref={`${sprite}#arrow`}></use>
                              </svg>
                            </div>
                          </button>
                          <div className={`item-pools-partnership__body ${activeSpoller === 2 ? 'active' : ''}`}>
                            <div className="item-pools-partnership__inner">
                              <div className="item-pools-partnership__descr">
                                <div className="item-pools-partnership__amount-container">
                                  <div className="item-pools-partnership__amount">
                                    <span className="text-yellow">Your stake:</span> 625 789.65257 AVO
                                  </div>
                                  <div className="item-pools-partnership__amount">
                                    <span className="text-yellow">Your earn: </span>
                                    12756.15265 NMX
                                  </div>
                                </div>
                                <div className="item-pools-partnership__timer timer">
                                  <h3 className="timer__title">Time left:</h3>
                                  <div className="timer__items">
                                    <div className="timer__block">
                                      <div className="timer__time">56</div>
                                      <div className="timer__label">D</div>
                                    </div>
                                    <div className="timer__block">
                                      <div className="timer__time">17</div>
                                      <div className="timer__label">H</div>
                                    </div>
                                    <div className="timer__block">
                                      <div className="timer__time">29</div>
                                      <div className="timer__label">M</div>
                                    </div>
                                    <div className="timer__block">
                                      <div className="timer__time">43</div>
                                      <div className="timer__label">S</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="item-pools-partnership__transactions">
                                <div className="transactions">
                                  <div data-tabs className="transactions__tabs tabs">
                                    <nav data-tabs-titles className="transactions__navigation tabs__navigation">
                                      <button
                                        type="button"
                                        className={`transactions__title tabs__title ${
                                          activeSpollerTab === 0 ? '_tab-active' : ''
                                        }`}
                                        onClick={() => setActiveSpollerTab(0)}
                                      >
                                        Stake
                                      </button>
                                      <button
                                        type="button"
                                        className={`transactions__title tabs__title ${
                                          activeSpollerTab === 1 ? '_tab-active' : ''
                                        }`}
                                        onClick={() => setActiveSpollerTab(1)}
                                      >
                                        withdraw
                                      </button>
                                    </nav>
                                    <div data-tabs-body className="transactions__content tabs__content">
                                      <div
                                        className="transactions__body tabs__body"
                                        style={{ display: activeSpollerTab === 0 ? 'block' : 'none' }}
                                      >
                                        <form className="transactions__form">
                                          <label className="transactions__label">AVO Amount</label>
                                          <div className="transactions__control">
                                            <input type="number" className="transactions__input" placeholder="500000" />
                                            {/* <button className="transactions__btn-max">MAX</button> */}
                                          </div>
                                          <button className="transactions__button button">Approve</button>
                                        </form>
                                      </div>
                                      <div
                                        className="transactions__body tabs__body"
                                        style={{ display: activeSpollerTab === 1 ? 'block' : 'none' }}
                                      >
                                        <form className="transactions__form">
                                          <label className="transactions__label">AVO Amount</label>
                                          <div className="transactions__control">
                                            <input type="number" className="transactions__input" placeholder="500000" />
                                            {/* <button className="transactions__btn-max">MAX</button> */}
                                          </div>
                                          <button className="transactions__button button">Withdraw</button>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
              {account && (
                <div className="staking__my-staking">
                  <div className="my-staking">
                    <h2 className="my-staking__title text-yellow">MY STAKING</h2>
                    <div className="my-staking__table">
                      <div className="my-staking__head">
                        <div className="my-staking__items my-staking__items--head">
                          <div className="my-staking__item">Staking amount</div>
                          <div className="my-staking__item">Time left</div>
                          <div className="my-staking__item">Your APR</div>
                          <div className="my-staking__item">Expected Revenue</div>
                          <div className="my-staking__item">Action</div>
                        </div>
                      </div>
                      <div className="my-staking__body">
                        {myStakes.length === 0 ? (
                          <div
                            style={{
                              textAlign: 'center',
                              padding: '20px',
                              backdropFilter: 'blur(24px)',
                              background: 'linear-gradient(135deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%)',
                              borderRadius: '10px',
                            }}
                          >
                            You have no staking positions yet
                          </div>
                        ) : (
                          myStakes.map((stake) => (
                            <div
                              key={stake.stakedTime}
                              className="my-staking__items my-staking__items--data card card--my-staking"
                            >
                              <div className="my-staking__item">
                                <div className="my-staking__item-label">Staking amount</div>
                                <div className="my-staking__amount">
                                  <img
                                    src={require('./img/logo-icon.svg').default}
                                    alt=""
                                    className="my-staking__amount-icon"
                                  />
                                  <div className="my-staking__revenue">
                                    <div className="my-staking__revenue-avo">
                                      {new Intl.NumberFormat('fr-FR').format(ethers.formatEther(stake.amount))} AVO
                                    </div>
                                    <div className="my-staking__revenue-convert text-yellow">~ $ 1 307.15</div>
                                  </div>
                                </div>
                              </div>
                              <div className="my-staking__item">
                                <div className="my-staking__item-label">Time left</div>
                                <div className="my-staking__item-time">
                                  {calculateTimeLeft(Number(stake.stakedTime), Number(stake.duration))}
                                </div>
                              </div>
                              <div className="my-staking__item">
                                <div className="my-staking__item-label">Your APR</div>
                                <div className="my-staking__item-apr text-yellow">{Number(stake.rate) / 100}%</div>
                              </div>
                              <div className="my-staking__item">
                                <div className="my-staking__item-label">Expected Revenue</div>
                                <div className="my-staking__revenue">
                                  <div className="my-staking__revenue-avo">
                                    {new Intl.NumberFormat('fr-FR').format(ethers.formatEther(stake.reward))} rAVO
                                  </div>
                                  <div className="my-staking__revenue-convert text-yellow">~ $ 1 307.15</div>
                                </div>
                              </div>
                              <div className="my-staking__item">
                                <div className="my-staking__action">
                                  <button
                                    onClick={() => {
                                      setModalUnstakeShow(true)
                                      setStakingDuration(
                                        durations.findIndex((d) => Number(d) === Number(stake.duration))
                                      )
                                      setUnstakeObject(stake)
                                    }}
                                    className="my-staking__button button"
                                  >
                                    Unstake
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="staking__footnotes">
              <div className="footnotes">
                <div className="footnotes__content">
                  <p className="footnotes__text">
                    <span>APR</span> — Annual Percentage Rate is a standardized metric used to express the cost of the
                    potential return on staking a certain amount of cryptocurrency over a year.
                  </p>
                  <p className="footnotes__text">
                    <span>TVL</span> — Total value locked is a metric used in the cryptocurrency sector to determine the
                    total U.S. dollar value of digital assets staked via decentralized finance (DeFi) platforms
                    or decentralized applications (dApps). The higher the TVL of a project, the more secure and valuable
                    it is perceived to be.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </main>
        <footer className="footer">
          <div className="footer__container ">
            <div className="footer__column">
              <a href="/" className="footer__logo logo logo">
                <img
                  className="logo__icon logo__icon--footer"
                  src={require('./img/logo-icon.svg').default}
                  alt="logo"
                />
                <div className="logo__name logo__name--footer">
                  <img
                    className="logo__name-icon logo__name-text--footer"
                    src={require('./img/logo-name.svg').default}
                    alt="logo"
                  />
                  <span className="logo__name-text logo__name-text--footer">finance</span>
                </div>
              </a>
              <p className="footer__text">The future that is already accessible now</p>
            </div>
            <div className="footer__column">
              <a href="https://avo.center/en" target="_blank" rel="noreferrer" className="footer__about">
                About AVOUNIVERSE
              </a>
            </div>
          </div>
        </footer>
      </div>
      <div className={`modal ${modalShow || modalUnstakeShow ? 'is-open' : ''}`}>
        <div className={`modal__container ${modalShow ? 'modal-open' : ''}`} role="dialog">
          <button className="modal__close js-modal-close" onClick={() => setModalShow(false)}>
            <svg className="modal__close-icon">
              <use xlinkHref={`${sprite}#close`}></use>
            </svg>
          </button>
          <div className="modal__body">
            <div className="modal__image">
              <picture>
                <source srcSet={safeWebp} type="image/webp" />
                <img src={require('./img/safe.png')} className="image" alt="" />
              </picture>
            </div>

            <h2 className="modal__title">STAKING</h2>
            <p className="modal__text">
              You have selected staking pool for <br />
              <span className="text-yellow">{convertSecondsToDuration(Number(durations[stakingDuration]))}</span>,
              please specify the number of AVO you wish to stake:
            </p>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                stakeHandler(e.target.stakeValue.value)
              }}
              className="modal__form"
            >
              <label className="modal__form-label">AVO Amount</label>
              <div className="modal__form-control">
                <input
                  disabled={loading}
                  type="number"
                  id="stakeValue"
                  className="modal__form-input"
                  placeholder="500000"
                />
                {/* <button className="modal__form-btn-max">MAX</button> */}
              </div>
              <div className="modal__form-app">
                Your <span className="text-yellow">APR {Number(rates[stakingDuration]) / 100}%</span>
              </div>
              {!loading ? (
                <button className="modal__form-button button js-modal-close">STAKE</button>
              ) : (
                <PropagateLoader color="#e3fb4c" style={{ margin: 'calc(2.6875rem / 2) auto' }} />
              )}
            </form>
          </div>
        </div>

        <div className={`modal__container ${modalUnstakeShow ? 'modal-open' : ''}`} role="dialog">
          <button className="modal__close js-modal-close" onClick={() => setModalUnstakeShow(false)}>
            <svg className="modal__close-icon">
              <use xlinkHref={`${sprite}#close`}></use>
            </svg>
          </button>
          <div className="modal__body">
            <div className="modal__image">
              <picture>
                <source srcSet={safeWebp} type="image/webp" />
                <img src={require('./img/safe.png')} className="image" alt="" />
              </picture>
            </div>

            <h2 className="modal__title">UNSTAKING</h2>
            <p className="modal__text">
              You are unstaking tokens for amount
              <br />
              <span className="text-yellow">{unstakeObject && ethers.formatEther(unstakeObject?.amount)} AVO</span>,
            </p>
            <div className="modal__unstake">
              <p className="modal__unstake-text">Unstake fee 5%</p>
              <p className="modal__unstake-text">
                Your <span className="text-yellow">APR {Number(unstakeObject?.rate) / 100}%</span>
              </p>
            </div>
            {!loading ? (
              <button onClick={unstakeHandler} className="modal__form-button button js-modal-close">
                UNSTAKE
              </button>
            ) : (
              <div style={{ textAlign: 'center', minHeight: '2.6875rem', alignContent: 'center' }}>
                <PropagateLoader color="#e3fb4c" />
              </div>
            )}
          </div>
        </div>
        <div
          className="modal__overlay"
          onClick={() => {
            setModalUnstakeShow(false)
            setModalShow(false)
          }}
        ></div>
      </div>
      <Snackbar open={alertShow} autoHideDuration={3000} onClose={() => setAlertShow(false)}>
        <div
          onClose={() => setAlertShow(false)}
          style={{
            width: '100%',
            height: '50px',
            backgroundColor: 'white',
            borderRadius: '5px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'black',
            padding: '0 10px',
          }}
        >
          {alertText}
        </div>
      </Snackbar>
    </div>
  )
}

export default App
